<template>
  <div class="aboutUs">
    <p class="top_title">公司介绍</p>
    <p class="title">北京瑞尔视科技有限公司是RealNetworks在中国境内的全资子公司，于2006年在北京成立。</p>
    <p class="title">RealNetworks公司成立于1995年，总部位于美国西雅图，全球约有18个世界各地的分支机构。我们于1995年发明了流媒体技术，并持续直接的或通过合作伙伴与消费者建立联系。我们通过数字媒体服务和产品帮助消费者、运营商和其他商业伙伴更好的生活及服务消费者。公司目前有移动服务、消费者媒体、游戏业务三大事业部。RealNetworks致力于提供优质的、高清的、个性化的视频、音乐及娱乐体验。我们将持续的提供创新发展的新产品线，使得视频更便捷、更有趣、更方便。</p>
    <br>
    <p class="title">“RealPlayer播放器”是由RealNetworks开发的跨平台的播放器，深受客户喜爱。</p>
    <p class="top_title">成长历程</p>
    <div class="msc-r">
      <img src="@/img/aboutus03.png" alt="">
    </div>
  </div>
</template>
<script>
  export default {
    name: 'aboutUs',
    created:function(){
        window.scrollTo(0,0);
    },
    methods:{
      
    }
  }
</script>
<style scoped>
  .aboutUs{width:60%;margin: 0 auto;}
  .top_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .sub_title{font-size: 28px;color: #00a7e1;margin: 20px 0;font-weight: bold;}
  .title{font-size: 20px;margin: 10px 0;}
  .content,th,td{text-indent:2em;font-size: 14px;line-height: 28px;}
  ul{margin-left: 40px;}
  li{font-size: 20px;line-height: 28px;}
  table{border-top: 1px solid #333333;margin: 20px 0;border-left: 1px solid #333333;}
  th{font-size: 14px;}
  th,td{border-right: 1px solid #333;border-bottom: 1px solid #333;padding: 20px;}
  .msc-r img{max-height: 350px!important;display: block;margin: 0 auto;}
</style>